<template>
  <el-dialog
    title="Pause or Unpause Orders"
    :visible.sync="shouldShow"
    @close="closeEvent"
    append-to-body
    width="30%"
  >
    <el-row>
      <el-col :span="24">
        <el-radio-group
          v-model="action"
          class="eden-switch-radio small"
          @change="resetForm"
        >
          <el-radio-button label="pause">Pause</el-radio-button>
          <el-radio-button label="unpause">Unpause</el-radio-button>
        </el-radio-group>
      </el-col>
    </el-row>
    <el-form :model="form" label-position="top" ref="form">
      <el-row>
        <el-col :span="24">
          <el-form-item label="Service" prop="service" :rules="validateField()">
            <el-select v-model="form.service" placeholder="Select service">
              <el-option label="All" value="all"></el-option>
              <el-option
                v-for="(service, index) in services"
                :key="index"
                :label="`${formatText(service)}`"
                :value="service"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="10">
        <el-col :span="12">
          <el-form-item
            label="Start Date"
            prop="start_date"
            :rules="validateField()"
          >
            <el-date-picker
              placeholder="Start"
              v-model="form.start_date"
              value-format="yyyy-MM-dd"
              type="date"
              format="dd MMM, yyyy"
              prefix-icon="eden-icon-calendar"
              :picker-options="{
                disabledDate: disabledDates,
              }"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="End Date"
            prop="end_date"
            :rules="validateField()"
          >
            <el-date-picker
              placeholder="End"
              v-model="form.end_date"
              value-format="yyyy-MM-dd"
              type="date"
              format="dd MMM, yyyy"
              prefix-icon="eden-icon-calendar"
              :picker-options="{
                disabledDate: disabledDates,
              }"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button type="primary" :loading="updating" @click="setOrders">{{
        action === "pause" ? "Pause" : "Unpause"
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import customer from "@/requests/customers/customer/actions";

export default {
  name: "CustomerOrdersPause",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      action: "pause",
      updating: false,
      form: {
        service: "",
        daterange: [],
        start_date: "",
        end_date: "",
      },
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    services() {
      const { plan: plan = {} } = this.customer.next_billing_date[0];
      return Object.keys(plan);
    },
    nextChargeDate() {
      const { next_charge_date: next_charge_date = "" } =
        this.customer.next_billing_date[0];
      return next_charge_date;
    },
  },
  methods: {
    disabledDates(time) {
      if (this.allowAccessFor(["superadmin", "admin", "gardener_pro"])) {
        return false;
      }

      const yesterday = new Date(
        new Date().getTime() - 2 * 24 * 60 * 60 * 1000,
      );
      const lastServiceDay = new Date(this.nextChargeDate).getTime();
      return time.getTime() < yesterday || time.getTime() > lastServiceDay;
    },
    closeEvent() {
      this.shouldShow = false;
      this.resetForm();
    },
    resetForm() {
      this.$refs.form.resetFields();
    },
    setOrders() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.updating = true;
        const payload = {
          service: this.form.service,
          start_date: this.form.start_date,
          end_date: this.form.end_date,
          user_id: this.customer.id,
        };

        customer
          .ordersStatus(this.action, payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.$emit("success");
              this.closeEvent();
              this.updating = false;
            } else {
              this.$message.error(response.data.message);
              this.updating = false;
            }
          })
          .catch((error) => {
            this.$message.error(error.response.data.message);
            this.updating = false;
          });
        return true;
      });
    },
  },
};
</script>
